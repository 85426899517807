@import "../../../config/theme/theme";
@import "../../../assets/sass/functions/functions";

.header {
  $space: 10px;

  height: 10vh;
  width: 100%;
  background: get($theme, ("palette", "background", "gradient"));
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  column-gap: 15px;
  grid-template-areas: "back-btn title home-btn logout-btn";

  &__back-btn {
    grid-area: back-btn;
    padding-left: 5rem;
  }

  &__title {
    grid-area: title;
    justify-self: center;
    align-self: center;
    color: #ffffff;
    font-family: Anton, serif;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    font-size: 3vh;
    margin: unset;
  }

  &__home-btn {
    grid-area: home-btn;
    padding-right: 5rem;
  }

  &__logout-btn {
    grid-area: logout-btn;
    padding-right: 30px;
  }

  &__btn {
    background-color: transparent;
    border: none;
    svg {
      cursor: pointer;
      width: 3.5rem;
      height: 3.5rem;
    }
  }
}
