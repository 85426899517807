@import "../../../../assets/sass/functions/functions";

.schedule-sign-up {
  $space: 3.5rem; // used for padding
  $form-width: calc(70% - #{$space} * 2);
  height: calc(100vh - 70px); // TODO: globalize 70px (header height)

  &::after {
    content: "";
    display: block;
    width: 40%;
    height: 100%;
    background-image: url("../../../../assets/img/backgrounds/hearing_test_signup.jpg");
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }

  $form-interspace: 1.2rem;

  &__subtitle {
    grid-area: subtitle;
    font-family: "Anton", serif;
    font-size: scaleFromIpad(30);
    line-height: scaleFromIpad(55.5);
    margin: 0 0 $form-interspace 0;
  }

  &__form {
    width: $form-width;
    height: calc(100% - #{$space} * 2);
    padding: $space;
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    grid-template-rows: repeat(6, 1fr);
    grid-template-areas:
      "subtitle subtitle subtitle subtitle subtitle . "
      "lastname lastname lastname address address address"
      "firstname firstname firstname city city city"
      "email email email postal_code postal_code postal_code"
      "birthdate birthdate birthdate gender gender gender"
      "submit-btn submit-btn . . . .";
    row-gap: 20px;

    & > * {
      height: fit-content;
      margin-right: 50px;
    }

    [data-input-name="lastname"] {
      grid-area: lastname;
    }

    [data-input-name="firstname"] {
      grid-area: firstname;
    }

    [data-input-name="email"] {
      grid-area: email;
    }

    [data-input-name="address"] {
      grid-area: address;
    }

    [data-input-name="city"] {
      grid-area: city;
    }

    [data-input-name="postal_code"] {
      grid-area: postal_code;
    }

    [data-input-name="birthdate"] {
      grid-area: birthdate;
    }

    .schedule-sign-up__gender-container {
      grid-area: gender;
      display: flex;
      flex-wrap: wrap;

      p {
        width: 100%;
        font-size: scaleFromIpad(20);
        line-height: scaleFromIpad(45);
        font-weight: 500;
        color: #424242;
        margin: 0;
      }

      [name="gender"] {
        width: 1.5rem;
        height: 1.5rem;
        box-shadow: unset;
      }

      .input-wrapper {
        flex-direction: row;
        align-items: center;
        justify-content: start;
        width: 50%;

        label {
          font-weight: 300;
          font-size: scaleFromIpad(20);
        }

        input {
          margin-left: 2rem;
          width: scaleFromIpad(25);
          height: scaleFromIpad(25);
        }
      }
    }

    button[type="submit"] {
      grid-area: submit-btn;
      font-size: scaleFromIpad(20);
      margin-top: $form-interspace;
    }
  }
}
