:root {
  --app-primary: #0164ff;
  --app-secondary: #003b9d;
  --app-info: #666;
  --app-error: #f44336;
  --app-dark: #424242;
  --app-gradient: linear-gradient(to right, #0164ff, #003b9d);
  --app-inverted-gradient: linear-gradient(to left, #0164ff, #003b9d);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden; /** no view w/ scroll */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.adm-picker-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
}

.adm-picker-header-button {
  font-weight: bold;
  color: var(--app-primary);
}

.adm-picker-header-title {
  flex: none;
}

@media (orientation: portrait) {
  .view-wrapper:not(.view-wrapper--full-height) {
    grid-template-rows: 93vh 7vh;
    grid-template-areas:
      "content"
      "header";

    .header {
      width: inherit;
      height: inherit;
      background: linear-gradient(to right, #0164ff, #003b9d);
      display: grid;
      grid-template-columns: auto 1fr auto;
      grid-column-gap: 15px;
      grid-template-areas: ". . ." "back-btn title home-btn" ". . . ";
      grid-template-rows: 1fr 1fr 1fr;
      place-items: center;

      .header__title {
        color: #ffffff;
        font-family: Anton, serif;
        font-style: normal;
        font-weight: 400;
        margin: unset;
        grid-area: title;
        font-size: 3rem;
        line-height: 3rem;
      }
    }
  }

  .dashboard {
    all: revert;
    width: inherit;
    height: inherit;
    display: grid;
    place-items: center;
  }

  .dashboard__btn[data-key="hearing-test"] {
    all: revert;
    background-image: url("assets/img/backgrounds/1080x1920/dashboard_hearing_test.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: inherit;
    width: inherit;
    display: grid;
    grid-template-rows: repeat(7, 1fr);
    justify-content: center;
    align-items: end;
    cursor: pointer;

    .dashboard__btn-text {
      margin-bottom: revert;
      border-radius: 60px;
      grid-row: 6 / span 1;
      background: rgba(255, 255, 255, 0.2);
      font-size: 4.5rem;
      line-height: 4.5rem;
      padding-inline: 30px;
      padding-block: 20px;
      border: 2px solid white;
    }
  }

  .hearing-test-sign {
    background-image: url("assets/img/backgrounds/1080x1920/hearing_test_sign_choices.jpg");
    background-repeat: no-repeat;
    background-size: cover;

    .selection-card {
      height: 40%;
      justify-content: center;
      align-items: flex-end;
      flex-flow: wrap;

      .padding-wrapper {
        padding: 5rem 4rem;
      }
    }
  }

  .hearing-test-sign-up::after {
    all: revert;
  }

  .hearing-test-sign-up {
    width: initial;
    height: initial;
    background-image: url("assets/img/backgrounds/1080x1920/hearing_test_signup.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    align-items: end;
    justify-content: center;
  }

  .hearing-test-sign-up__form {
    width: inherit;
    height: inherit;
    padding: 0 3.5rem 8.5rem 3.5rem;
    min-width: 70vw;
    grid-template-areas:
      "subtitle subtitle subtitle subtitle subtitle subtitle "
      "lastname lastname lastname firstname firstname firstname"
      "address address address postal_code postal_code postal_code"
      "email email email gender gender gender"
      "secondarySubtitle secondarySubtitle secondarySubtitle . submit-btn submit-btn ";
  }

  .hearing-test-sign-in::after {
    all: revert;
  }

  .hearing-test-sign-in {
    width: inherit;
    height: inherit;
    justify-content: center;
    background-image: url("assets/img/backgrounds/1080x1920/hearing_test_signin.png");
    background-repeat: no-repeat;
    background-size: cover;

    .hearing-test-sign-in__form {
      width: 50vw;
      padding-left: 18vw;
    }

    .hearing-test-sign-in__subtitle {
      padding-top: 20vh;
      font-size: 1.8rem;
    }
  }

  .hearing-test-welcome::after {
    all: revert;
    content: "";
    display: block;
    position: absolute;
    background-image: url("assets/img/backgrounds/1080x1920/dashboard_hearing_test.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }

  .hearing-test-welcome {
    width: 50%;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    justify-self: center;
    margin-bottom: 12vh;
    text-align: center;

    .hearing-test-welcome__subtitle {
      text-align: center;
      margin: unset;
      margin-bottom: 15%;
      max-width: 40vw;
    }

    .typography--text-color {
      color: white;
    }

    .hearing-test-welcome__btn {
      transition: all 300ms ease-in-out;
      min-width: 30vw;
      align-self: center;
      background: white;
      color: black;
      padding: 0.7rem;
    }
  }

  .hearing-test-view-wrapper {
    all: revert;
    display: grid;
    place-items: end center;
    background-repeat: no-repeat;
    background-size: cover;
    grid-area: content;

    .grid-layout {
      display: grid;
      grid-template-rows: 1fr 2fr 1fr;
      grid-template-areas: "title" "content" "buttons";
      width: 100%;
      height: 50%;
      place-items: center;
    }

    &.introduction {
      background-image: url("assets/img/backgrounds/1080x1920/hearing_test_introduction.png");
    }

    &.setup-instructions {
      background-image: url("assets/img/backgrounds/1080x1920/hearing_test_introduction.png");
    }

    &.hearing-test-measurement {
      background-image: url("assets/img/backgrounds/1080x1920/hearing_test_introduction.png");

      .hearing-test-view-wrapper__title {
        margin: unset;
      }

      .hearing-test-view-wrapper__content {
        height: inherit;
      }
    }

    &.age-range {
      background-image: url("assets/img/backgrounds/1080x1920/hearing_test_introduction.png");
    }

    &.questions {
      background-image: url("assets/img/backgrounds/1080x1920/hearing_test_introduction.png");

      .questions__text {
        font-size: 1.5rem;
      }
    }
  }

  .hearing-test-thanks {
    background-image: url("assets/img/backgrounds/1080x1920/hearing_test_thanks.jpg");
    background-repeat: no-repeat;
    background-size: cover;

    .hearing-test-thanks__content {
      all: revert;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 27vh 9vh 31.6vh 2vh;
      align-items: end;
      justify-items: center;

      * {
        margin: unset !important;
      }
    }

    .hearing-test-thanks__check-icon {
      grid-column: 2 / 3;
      width: 80px;
      height: 80px;
    }

    .hearing-test-thanks__title {
      grid-column: 2 / 3;
      text-align: center;
      font-size: 3rem;
      padding: 0 3rem;
    }

    button {
      grid-column: 2 / 3;
      height: max-content;
      font-size: 1.3rem !important;
    }

    .hearing-test-thanks__text {
      grid-column: 2 / 3;
    }
  }
}
